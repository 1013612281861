body {
  text-align: center;
  background-color: rgb(22, 22, 22);
  color: whitesmoke;
  background-size: 100vw 100vh;
  background-attachment: fixed;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
h1 {
  margin: 30px;
  font-weight: 800;
  font-style: oblique;
  font-size: 45px;
}
a {
  color: whitesmoke;
}
a:link {
  color: whitesmoke;
}
a:visited {
  color: whitesmoke;
}

.techList {
  margin: 10px;
  font-style: oblique;
}
.mainDiv {
  width: 100vw;
  height: 100vh;
}
header {
  position: sticky;
  top: 0px;
  z-index: 1030;
}
footer {
  position: fixed;
  text-align: center;
  bottom: 0px;
  height: 60px;
  width: 100vw;
  background-color: #343a40;
  z-index: 1030;
}
button {
  margin: 3px;
}
.dailyQuote {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}
.nameImage {
  border-radius: 5px;
}
.quote {
  position: static;
  margin-top: 11%;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
}
.copyright {
  text-align: right; 
  color: rgb(128, 128, 128);
  font-style: italic;
  margin-right: 20%;
}
.pdfViewer {
  width: 100vw;
  height: 100vh;
}
.contactInfo {
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  width: 50vw;
  margin: 5px auto;
  font-size: larger;
}
.contactInfoLine:hover {
  border-radius: 5px;
  margin: 0px;
  background-color: whitesmoke;
  color: black;
  font-style: italic;
  font-size: larger;
}
.emailForm {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
}
.card {
  background-color: #343a40;
}
.contact {
  background-color: darkblue;
}
input {
  width: 50vw;
  border-radius: 5px;
  margin: 1px;
  padding: 5px 10px;
}
.nameTagInput {
  max-width: 150px;
  text-align: center;
}
textarea {
  width: 50vw;
  border-radius: 5px;
  margin: 1px;
  padding: 5px 10px;
}
.nameTagProject {
  position: initial;
  width: 375px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
}
.nameTagAnimation {
  margin-top: 10%;
  position: absolute;
  left: 50vw;
  z-index: 1050;
  width: 375px;
  margin-left: -187.5px;
  margin-right: auto;
  animation-name: nameTag;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-timing-function: linear;
  transform-origin: initial;
}
@keyframes nameTag {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.25);
  }
  25% {
    transform: scale(1);
  }
  60% {
    transform: translate3d(75%, -45%, 0px) rotate(17deg) scale(0.75);
  }
  100% {
    transform: translate3d(140%, -80%, 0px) rotate(35deg) scale(0.5);
  }
}
.helloMyNameIs {
  position: relative;
  background-color: #f23b2b;
  color: white;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  max-width: 375px;
  height: 90px;
  font-family: Lato, sans-serif;
  font-style: bold;
  vertical-align: middle;
  padding-bottom: 5px;
}
.hello {
  position: relative;
  font-size: 40px;
  margin-bottom: -10px;
  font-weight: bold;
}
.mynameis {
  position: relative;
  font-size: 28px;
  font-weight: bold;
}
.name {
  position: relative;
  background-color: white;
  /* display: inline-block; */
  vertical-align: middle;
  color: black;
  text-align: center;
  width: 375px;
  font-size: 75px;
  height: 130px;
  line-height: 130px;
  font-family: "Noteworthy", sans-serif;
}
.bottomBar {
  position: relative;
  background-color: #f23b2b;
  height: 20px;
  border-radius: 0px 0px 20px 20px;
  max-width: 375px;
}

.cssTeamsMenu {
  position: absolute;
  transition: 0.5s;
  top: 35vh;
  padding-left: 5px;
}
.cssMenuTitle {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-style: oblique;
  font-size: x-large;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
}
.cssTeamsMenu:hover {
  margin-left: 110px;
}
.cssTeamsMenu:focus {
  margin-left: 110px;
  outline: none;
}
.cssTeamsMenu:focus-within {
  margin-left: 110px;
}

.teamIconContainer {
  position: inherit;
  left: -110px;
  width: 110px;
  top: -80px;
}
.menuTitle {
  padding-left: 5px;
}
.teamIcon {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  border: 1px solid transparent;
}
.teamIcon:hover {
  transform: scale(1.2) translateX(2px);
  border-radius: 3px;
  border: 1px solid whitesmoke;
  transition: 0.3s;
  cursor: pointer;
}
.teamImage {
  margin: 20px;
  border-radius: 50%;
  width: 200px;
  border: 10px solid whitesmoke;
}
.collage {
  width: 100%;
}
.aboutMe {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.5em;
  text-align: justify;
  font-weight: 500;
}
.maxWidthForText{

  max-width: 50%;
  min-width: 350px;
  margin: auto;
  padding: 20px;
}
.bottomPadding{
  padding-bottom: 70px;
}