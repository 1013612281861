.col-md-6 {
   padding: 15px;
}
.img-fluid{
    border-radius: 15px;
    max-height: 212px;
}
.techsUsed{
    font-style: oblique;
    font-weight: 700
    /* font-size: small; */
}
